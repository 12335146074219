<template>
  <v-container fluid class="down-top-padding tw-h-full">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Relatórios de Escolas">
      <iframe
        class="iframe-datastudio"
        :src="`https://datastudio.google.com/embed/u/0/reporting/616ea663-fcbf-47fc-bd50-1ecc32371815/page/p_czmql5uysc?params=${param}`"
        frameborder="0" style="border:0" allowfullscreen></iframe>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data: () => ({
    organizationId: '',
    page: {
      title: 'Relatórios'
    },
    param: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Relatórios',
        disabled: false,
        to: '/reports/schools',
        exact: true
      }

    ]
  }),
  computed: {
    ...mapState(['sidebarDrawer'])
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'setSidebarDrawer'
    })
  },
  beforeDestroy () {
    this.setSidebarDrawer(true)
  },
  async mounted () {
    this.param = this.$route.params.school
    const ps = JSON.parse(this.param)

    this.breadcrumbs.push({
      text: ps['ds83.school_name_param'],
      disabled: true
    })
    this.setSidebarDrawer(false)
    // this.getClassData()
  }

}
</script>
<style>
.container.page-wrapper.container--fluid{
  height: 100%;
}
.iframe-datastudio {
  width: 100%;
  height: 70vh;
}
</style>
